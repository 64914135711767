/* Dropzone */

.dropzone {
    border: 1px solid $border-color;
    min-height: 200px;
    background: $content-bg;
    .dz-message {
        text-align: center;
        width: 100%;
        span {
            font-size: 0.8125rem;
            color: $body-color;
        }
    }
    .dz-preview {
        margin: 5px;
        &.dz-image-preview {
            background: $content-bg;
        }
        .dz-image {
            width: 75px;
            height: 75px;
            border-radius: 6px;
        }
        .dz-error-message {
            top: 80px;
            left: -35px;
        }
        .dz-success-mark,
        .dz-error-mark {
            margin-left: -17px;
            svg {
                width: 35px;
                height: 35px;
            }
        }
    }
}
