/* Message Content */
.email-wrapper {
    .mail-view {
        background: $card-bg;
        .message-body {
            .sender-details {
                padding: 20px 15px 0;
                border-bottom: $border-property;
                @include display-flex;

                .details {
                    padding-bottom: 0;

                    .msg-subject {
                        font-weight: 600;
                    }

                    .sender-email {
                        margin-bottom: 20px;
                        font-weight: 400;

                        i {
                            font-size: 1rem;
                            font-weight: 600;
                            margin: 0 1px 0 7px;
                        }
                    }
                }
            }

            .message-content {
                padding: 50px 15px;
            }

            .attachments-sections {
                ul {
                    list-style: none;
                    border-top: $border-property;
                    padding: 30px 15px 20px;

                    li {
                        padding: 10px;
                        margin-right: 20px;
                        border: $border-property;
                        @include border-radius(5px);
                        @extend .d-inline-flex;
                        @extend .flex-row;
                        @extend .align-items-stretch;

                        .thumb {
                            display: inline-block;
                            margin-right: 10px;
                            @extend .d-flex;
                            @extend .justify-content-center;
                            @extend .align-items-center;

                            i {
                                font-size: 30px;
                                margin: 0;
                                color: $body-color;
                            }
                        }

                        .details {
                            p.file-name {
                                display: block;
                                @extend %ellipsor;
                                margin-bottom: 0;
                                color: $body-color;
                            }

                            .buttons {
                                @extend .d-flex;
                                @extend .align-items-center;

                                .file-size {
                                    margin-right: 10px;
                                    margin-bottom: 0;
                                    font-size: 13px;
                                }

                                a {
                                    font-size: 13px;
                                    margin-right: 10px;

                                    &:last-child {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
