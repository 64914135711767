/* Typeahead */

.tt-menu,
.gist {
    text-align: left;
}

.twitter-typeahead {
    max-width: 100%;
}

.typeahead,
.tt-query,
.tt-hint {
    @extend .form-control;
}

.typeahead {
    background-color: $input-bg;
    &:focus {
        border-color: $border-color;
    }
}

.tt-query {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
    color: color(gray);
}

.tt-menu {
    width: 100%;
    margin: 12px 0;
    padding: 8px 0;
    background-color: $dropdown-bg;
    border: 1px solid $border-color;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.tt-suggestion {
    padding: 3px 20px;
    font-size: inherit;
    &:hover {
        cursor: pointer;
        color: color(white);
        background-color: theme-color(primary);
    }
    .tt-cursor {
        color: color(white);
        background-color: theme-color(primary);
    }
    p {
        margin: 0;
    }
}
