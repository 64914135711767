/* CodeMirror */

.CodeMirror {
    font-size: $default-font-size;
    height: auto;
    text-align: left;
    min-height: auto;
}
.CodeMirror-scroll {
    min-height: auto;
}
