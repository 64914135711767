/* Full Calendar */

.fc {
    @media (max-width: 767px) {
        .fc-toolbar {
            .fc-left {
                float: none;
            }
            .fc-right {
                float: none;
                margin-top: 10px;
            }
            .fc-center {
                text-align: left;
                display: block;
                h2 {
                    margin-top: 10px;
                }
            }
        }
    }
    button {
        @extend .btn;
        @extend .btn-sm;
        height: auto;
        background-image: none;
        text-shadow: none;
        background-color: transparent;
        border-color: $border-color;
        text-transform: capitalize;
        color: $fullcalendar-color;
        .fc-icon-right-single-arrow,
        .fc-icon-left-single-arrow {
            &:after {
                color: $fullcalendar-color;
                font: normal normal normal 20px/1 "Material Design Icons";
            }
        }
        .fc-icon-right-single-arrow {
            &:after {
                content: "\F142";
            }
        }
        .fc-icon-left-single-arrow {
            &:after {
                content: "\F141";
            }
        }
        @media (max-width: 767px) {
            font-size: 12px;
            margin: 5px;
        }
    }
    .fc-button-group {
        @extend .btn-group;
        .fc-button {
            background-color: transparent;
            margin: 0;
            &.fc-state-active {
                color: theme-color(danger);
            }
        }
    }
    .fc-header-toolbar {
        margin-top: 2rem;
        .fc-center {
            h2 {
                font-size: 1rem;
            }
        }
    }
    .fc-widget-header {
        border: 0;
        table {
            tr {
                th {
                    border-width: 0 0 1px 0;
                    text-align: right;
                    padding: 1rem;
                    @media (max-width: 767px) {
                        padding: 0.125rem;
                        font-size: 12px;
                    }
                    span {
                        font-size: 10px;
                        text-transform: uppercase;
                        color: $fullcalendar-color;
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }
    .fc-content-skeleton {
        table {
            tr {
                td {
                    font-size: 0.875rem;
                }
            }
        }
    }
    .fc-event {
        border: 0;
        color: $white;
        padding: 0.5rem;
        &.fc-start {
            background: theme-gradient-color(primary);
            border-radius: 4px;
            border-left: 4px solid theme-color(primary);
        }
        &.fc-not-start,
        &.fc-not-end {
            background: theme-gradient-color(danger);
            border-left: 4px solid theme-color(danger);
            padding-left: 0.5rem;
        }
        &.fc-not-end {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .fc-title,
        .fc-time {
            color: $white;
        }
    }
}

.fc-external-events {
    .fc-event {
        background: linear-gradient(to bottom, #222328, #1a191d);
        color: $white;
        border-width: 0 0 0 4px;
        padding: 16px;
        border-radius: 0;
        margin-bottom: 10px;
        &:nth-child(1) {
            border-color: theme-color(primary);
        }
        &:nth-child(2) {
            border-color: theme-color(danger);
        }
        &:nth-child(3) {
            border-color: theme-color(success);
        }
        &:nth-child(4) {
            border-color: theme-color(info);
        }
        &:nth-child(4) {
            border-color: theme-color(warning);
        }
    }
}
