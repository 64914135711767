/* Js-grid */

.jsgrid {
    .jsgrid-grid-header {
        border: 1px solid $border-color;
        border-top: 0;
        background: inherit;
        .jsgrid-header-cell {
            background-color: inherit;
            border-color: $border-color;
        }
    }
    .jsgrid-table {
        @extend .table;
        th {
            font-weight: initial;
        }
        .jsgrid-filter-row {
            input[type="text"],
            select {
                @extend .form-control;
            }
            input[type="number"] {
                @extend .form-control;
                padding: 0.56rem 0;
            }
        }
    }
    .jsgrid-header-sort {
        &:before {
            margin-top: 10px;
            float: right;
        }
    }
    .jsgrid-header-sort-asc {
        &:before {
            border-color: transparent transparent $body-color;
        }
    }
    .jsgrid-header-sort-desc {
        &:before {
            border-color: $body-color transparent transparent;
        }
    }
    .jsgrid-pager {
        line-height: 2;
        @extend .pagination;
        .jsgrid-pager-nav-button,
        .jsgrid-pager-page {
            @extend .page-item;
            a {
                @extend .page-link;
            }
        }
    }
    .jsgrid-pager-current-page {
        padding: 0.5rem 0.75rem;
        font-weight: initial;
        line-height: 1.25;
    }
    .jsgrid-row {
        .jsgrid-cell {
            background: inherit;
            border: 1px solid $border-color;
        }
    }
    .jsgrid-alt-row {
        .jsgrid-cell {
            background: #1c1e2f;
            border: 1px solid $border-color;
        }
    }
    .jsgrid-filter-row {
        .jsgrid-cell {
            background: inherit;
            border: 1px solid $border-color;
        }
    }
    .jsgrid-grid-body {
        border: 1px solid $border-color;
    }
}
