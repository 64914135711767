/* Datepicker */
.datepicker.datepicker-dropdown,
.datepicker.datepicker-inline {
    padding: 0;
    width: 30%;
    max-width: 500px;
    min-width: 250px;

    .datepicker-days {
        padding: 0;

        table.table-condensed {
            width: 100%;

            thead {
                tr {
                    th {
                        text-align: center;
                        padding: 0.5rem 0;

                        &.prev {
                            color: color(gray);
                            padding-bottom: 1rem;
                            padding-top: 1rem;
                            background: transparent;
                        }

                        &.datepicker-switch {
                            color: color(gray);
                            background: transparent;
                            padding-bottom: 1rem;
                            padding-top: 1rem;
                            font-size: 1rem;
                            font-weight: 600;
                        }

                        &.next {
                            color: grey;
                            padding-bottom: 1rem;
                            padding-top: 1rem;
                            background: transparent;
                        }

                        &.dow {
                            font-family: $type-1;
                            color: color(gray);
                            font-size: 0.875rem;
                            font-weight: initial;
                        }
                    }
                }
            }

            tbody {
                position: relative;
                top: 13px;

                td {
                    text-align: center;

                    &.day {
                        font-size: 0.9375rem;
                        padding: 0.5rem 0;
                        color: $body-color;

                        &:hover {
                            background: transparent;
                        }

                        &.active {
                            color: #fff;
                            background: transparent;
                            position: relative;
                            z-index: 1;

                            &:before {
                                content: "";
                                width: 28px;
                                height: 28px;
                                background: theme-color(success);
                                @include border-radius(4px);
                                display: block;
                                margin: auto;
                                vertical-align: middle;
                                position: absolute;
                                top: 6px;
                                z-index: -1;
                                left: 0;
                                right: 0;
                            }
                        }

                        &.today {
                            color: #fff;
                            background: transparent;
                            position: relative;
                            z-index: 1;

                            &:before {
                                content: "";
                                width: 28px;
                                height: 28px;
                                background: theme-color(primary);
                                @include border-radius(4px);
                                display: block;
                                margin: auto;
                                vertical-align: middle;
                                position: absolute;
                                top: 6px;
                                z-index: -1;
                                left: 0;
                                right: 0;
                            }
                        }
                    }

                    &.old.day {
                        color: #464b57;
                    }

                    &.new.day {
                        color: $body-color;
                    }

                    &.range-start,
                    &.range-end {
                        background: transparent;
                        position: relative;
                        color: theme-color(success);
                        &::before {
                            content: "";
                            width: 28px;
                            height: 28px;
                            background: rgba(theme-color(success), 0.2);
                            border-radius: 4px;
                            display: block;
                            margin: auto;
                            vertical-align: middle;
                            position: absolute;
                            top: 6px;
                            z-index: -1;
                            left: 0;
                            right: 0;
                        }
                    }
                    &.range {
                        position: relative;
                        background: transparent;
                        &::before {
                            content: "";
                            width: 28px;
                            height: 28px;
                            background: #1b1b1b;
                            border-radius: 4px;
                            display: block;
                            margin: auto;
                            vertical-align: middle;
                            position: absolute;
                            top: 6px;
                            z-index: -1;
                            left: 0;
                            right: 0;
                        }
                    }
                }
            }
        }
    }
}

.datepicker.datepicker-inline {
    width: 100%;
    max-width: 100%;
    min-width: 250px;

    thead {
        tr {
            th {
                &.prev {
                    color: grey;
                    padding-bottom: 0.5rem;
                    padding-top: 0.5rem;
                }

                &.datepicker-switch {
                    color: theme-color(primary);
                    padding-bottom: 0.5rem;
                    padding-top: 0.5rem;
                }

                &.next {
                    color: grey;
                    padding-bottom: 0.5rem;
                    padding-top: 0.5rem;
                }

                &.dow {
                }
            }
        }
    }
}

.datepicker {
    > div {
        display: initial;
        padding: 0;
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        text-align: center;
        white-space: nowrap;
        border-radius: 2px;
    }

    &.input-group {
        border: 1px solid $border-color;
        padding: 0;

        .form-control {
            border: none;
        }
    }
}

.datepicker-dropdown {
    &:after {
        border-bottom-color: $dropdown-bg;
    }
    &:before {
        border-bottom-color: $border-color;
    }
    &.datepicker-orient-top {
        &:before,
        &:after {
            top: auto;
        }
        &:after {
            border-top-color: $dropdown-bg;
        }
        &:before {
            border-top-color: $border-color;
        }
    }
}
