/* Dropdowns */

.dropdown {
    .dropdown-toggle {
        &:after {
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-bottom: 0;
            font: normal normal normal 24px/1 "themify";
            content: "\e64b";
            width: auto;
            height: auto;
            vertical-align: middle;
            line-height: 0.625rem;
            font-size: 0.875rem;
            margin-left: 0.3rem;
        }
    }
    .dropdown-menu {
        margin-top: 0;
        font-size: $default-font-size;
        border-radius: 0;
        box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
        .dropdown-item {
            font-size: 0.875rem;
            padding: 0.25rem 1.5rem;
            &:hover {
                background-color: $dropdown-link-hover-color;
                color: $dropdown-link-color;
            }
            &:active {
                background: initial;
            }
        }
    }
}
