/* Tooltips */

.tooltip-static-demo {
    .tooltip {
        position: relative;
        display: inline-block;
        opacity: 1;
        margin: 0 10px 10px 0;
        z-index: 10;
    }
    .bs-tooltip-bottom-demo,
    .bs-tooltip-top-demo {
        .arrow {
            left: 50%;
        }
    }
    .bs-tooltip-right-demo,
    .bs-tooltip-left-demo {
        .arrow {
            &:before {
                top: calc((100%-#{$tooltip-arrow-width}) / 2);
            }
        }
    }
}

.tooltip {
    font-size: $tooltip-font-size;
    min-width: 5.625rem;
    .tooltip-inner {
        font-weight: 400;
    }
    z-index: 1029;
}

@each $color, $value in $theme-colors {
    .tooltip-#{$color} {
        @include tooltip-variant($color);
    }
}
